import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { createContextLogger } from '@konnektu/helpers';
import { map, tap } from 'rxjs';
import { AuthService } from './auth.service';

export const tenantAuthGuard =
  (tenantCode: string): CanActivateFn =>
  (_: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const authService = inject(AuthService);
    const router = inject(Router);
    const logger = createContextLogger('authFuncGuard');

    const redirectToLogin = (returnUrl?: string) => {
      const redirectUrl = `/${tenantCode}/login${returnUrl ? '?to=' + encodeURI(returnUrl) : ''}`;
      if (returnUrl) {
        return router.parseUrl(redirectUrl);
      }
      return router.parseUrl(redirectUrl);
    };

    return authService.isLoggedIn$.pipe(
      tap((res) => logger.debug(`Checked: ${state.url}. Result: ${res}`)),
      map((res) => res || redirectToLogin(state.url))
    );
  };
