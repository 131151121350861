import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable, Provider, inject } from '@angular/core';
import { createContextLogger } from '@konnektu/helpers';
import { API_URL } from '@konnektu/tokens';
import { Observable, catchError, switchMap, take, throwError } from 'rxjs';
import { AuthService } from './auth.service';

// TODO: тротлить зарпосы, так как если одновременно произойдет много запросов,
// а accessToken истек, то на бек уйдет больше чем 1 запрос на updateaccesstoken
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly logger = createContextLogger(`AuthInterceptor`);

  private readonly apiUrl = inject<string>(API_URL);

  private readonly authService = inject(AuthService);

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // Если реквест идет не к апишке или на нем стоит флажок что не нужно интерцептить,
    // то никак его не изменяем

    return this.authService.accessToken$.pipe(
      take(1),
      switchMap((accessToken) =>
        next
          .handle(accessToken ? this.addTokenHeader(req, accessToken) : req)
          .pipe(
            catchError((err) => {
              if (err instanceof HttpErrorResponse && err.status === 401) {
                this.logger.error(
                  `Request failed, because current user is unauthorized. Redirecting to login page.`
                );
                return throwError(() => err);
              }
              this.logger.error('Request in interceptor failed', err);
              return throwError(() => err);
            })
          )
      )
    );
  }

  private addTokenHeader(
    request: HttpRequest<unknown>,
    token: string
  ): HttpRequest<unknown> {
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`)
    });
  }
}

export const authInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true
};
