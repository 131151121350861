import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { createContextLogger } from '@konnektu/helpers';
import { injectTenantNavigate } from '@konnektu/multitenant';
import { API_URL, INSTANCE_CODE } from '@konnektu/tokens';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import {
  BehaviorSubject,
  Observable,
  catchError,
  distinctUntilChanged,
  map,
  of,
  tap
} from 'rxjs';
import { LoginResult } from './dtos';
import { SHOULD_INTERCEPT } from './should-intercept.token';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly apiUrl = inject(API_URL);

  private readonly http = inject(HttpClient);

  private readonly router = inject(Router);

  private readonly route = inject(ActivatedRoute);

  public readonly loginUrl = `${this.apiUrl}/auth/login`;

  public readonly refreshTokenUrl = `${this.apiUrl}/auth/updateaccesstoken`;

  public readonly checkInstanceUrl = `${this.apiUrl}/auth/checkinstance`;

  private readonly tempFingerprint = '123456789';

  private readonly instanceCode = inject(INSTANCE_CODE);

  private readonly navigateTenant = injectTenantNavigate();

  private readonly logger = createContextLogger('AuthService');

  accessToken$ = new BehaviorSubject<string | null>(null);

  refreshToken$ = new BehaviorSubject<string | null>(null);

  accessTokenPayload$ = this.accessToken$.pipe(
    distinctUntilChanged(),
    map((token) =>
      token
        ? jwtDecode<JwtPayload & { InstanceCode: string; OperatorId: number }>(
            token
          )
        : null
    )
  );

  currentOperatorId$ = this.accessTokenPayload$.pipe(
    map((payload) => (payload ? Number(payload.OperatorId) : null))
  );

  refreshTokenPayload$ = this.refreshToken$.pipe(
    map((token) => (token ? jwtDecode<JwtPayload>(token) : null))
  );

  isLoggedIn$ = this.accessTokenPayload$.pipe(
    map((payload) => {
      if (payload) {
        const currentUnix = Date.now() / 1000;
        const isTokenForCurrentInstance =
          payload.InstanceCode === this.instanceCode;
        return (
          payload &&
          payload.exp &&
          !(currentUnix > payload.exp) &&
          isTokenForCurrentInstance
        );
      }
      return false;
    })
  );

  constructor() {
    const accessToken = localStorage.getItem('at');
    const refreshToken = localStorage.getItem('rt');
    if (accessToken && refreshToken) {
      this.setAccessToken(accessToken);
      this.setRefreshToken(refreshToken);
    }
  }

  login(login: string, password: string): Observable<{ error?: string }> {
    return this.http
      .post<LoginResult>(
        this.loginUrl,
        {
          login,
          password,
          fingerprint: this.tempFingerprint
        },
        {
          context: new HttpContext().set(SHOULD_INTERCEPT, false)
        }
      )
      .pipe(
        tap((result) => {
          if (result.accessToken && result.success && result.refreshToken) {
            this.setAccessToken(result.accessToken);
            this.setRefreshToken(result.refreshToken);
          }
        }),
        map((result) => {
          if (!result.success) {
            return { error: 'LoginError.InvalidCredentials' };
          }
          return {};
        }),
        tap(({ error }) => {
          if (!error) {
            const toParam = this.route.snapshot.queryParamMap.get('to');
            if (toParam) {
              void this.router.navigateByUrl(decodeURI(toParam));
            } else {
              void this.navigateTenant([]);
            }
          }
        }),
        catchError(() => of({ error: 'LoginError.Unknown' }))
      );
  }

  logout(to?: string): void {
    localStorage.removeItem('at');
    localStorage.removeItem('rt');
    void this.navigateTenant(['login'], { queryParams: to ? { to } : {} });
  }

  private setAccessToken(token: string) {
    localStorage.setItem('at', token);
    this.accessToken$.next(token);
  }

  private setRefreshToken(token: string) {
    localStorage.setItem('rt', token);
    this.refreshToken$.next(token);
  }
}
