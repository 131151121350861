import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { createContextLogger } from '@konnektu/helpers';
import { Observable, map, tap } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  private readonly logger = createContextLogger('AuthGuard');

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService
  ) {}

  canActivate(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.check(state);
  }

  canActivateChild(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.check(state);
  }

  check(state: RouterStateSnapshot): Observable<UrlTree | boolean> {
    return this.authService.isLoggedIn$.pipe(
      tap((res) => this.logger.debug(`Checked: ${state.url}. Result: ${res}`)),
      map((res) => res || this.redirectToLogin(state.url))
    );
  }

  private redirectToLogin(returnUrl?: string) {
    const redirectUrl = `/login${returnUrl ? '?to=' + encodeURI(returnUrl) : ''}`;
    if (returnUrl) {
      return this.router.parseUrl(redirectUrl);
    }
    return this.router.parseUrl(redirectUrl);
  }
}
